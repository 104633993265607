<div class="project">
  <app-nav
    [isDarkBg]="false">
  </app-nav>


  <carousel class="desktop-images" [noPause]="false" [showIndicators]="false" [interval]="50000">
    <slide *ngFor="let image of images">
      <img [src]="image" [alt]="image" style="display: block; width: 100%;">
    </slide>
  </carousel>

  
  <carousel class="mobile-images" [noPause]="false" [showIndicators]="false" [interval]="50000">
    <slide *ngFor="let image of mobileImages">
      <img [src]="image" [alt]="image" style="display: block; width: 100%;">
    </slide>
  </carousel>

  <div class="description">
    <h2>{{description.title}}</h2>
    <h4 class="subheader">{{description.teqnique}}</h4>
    <br/>
    <div>TR</div>
    <p>
      {{description.turkish}}
    </p>
    <div>ENG</div>
    <p>
      {{description.english}}
    </p>
    
  </div>
</div>