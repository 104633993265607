import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() isDarkBg?:boolean = true
  public isCollapsed = true; 
  public links = [
    {href: '/works/hammam', name: 'Hammam / SF March (2019 - 2023)'},
    {href: '/works/intentionality', name: 'Intentionality / SF March (2019 - 2023)'},
    {href: '/works/blinded', name: 'Blinded / SF March (2018 - 2023)'},
    {href: '/works/fatma', name: 'Fatma / Gallery Zmix / SF March (2016-2023'},
    {href: '/works/ayse', name: 'Ayse / Gallery Zmix / SF March (2016-2023)'},
    {href: '/works/punctum', name: 'Punctum / Yadigar (2022)'},
    {href: '/works/sabun', name: 'Soap / Yadigar (2022)'},
    {href: '/works/nalin', name: 'Nalin / Yadigar (2022)'},
    {href: '/works/hamamtasi', name: 'Hammam Tasi / Yadigar (2022)'},
    {href: '/works/onk', name: 'ONK / Gazeteciler Cemiyeti Muzesi (2022)'},
    {href: '/works/kabak', name: 'Tohumlu Kabak'},
    {href: '/works/konusanlar', name: 'Konusanlar/ Gazeteciler Cemiyeti Muzesi (2022)'},
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
