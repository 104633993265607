
<app-nav></app-nav>
<carousel class="desktop-images" [noPause]="false" [showIndicators]="false" [interval]="5000">
  <ng-container *ngFor="let slide of sliders">
    <slide>
      <img [src]="'/assets/images/home/desktop/' + slide.src" [alt]="slide.title" style="display: block; width: 100%;">
      <a [href]="slide.href">
        <div class="carousel-caption-section d-none d-md-block">
          <h3>{{slide.title}}</h3>
          <p>{{slide.description}}</p>
        </div>
      </a>
    </slide>
  </ng-container>
</carousel> 

<carousel class="mobile-images" [noPause]="false" [showIndicators]="false" [interval]="5000">
  <ng-container *ngFor="let slide of sliders">
    <slide>
      <img [src]="'/assets/images/home/mobile/' + slide.src" [alt]="slide.title" style="display: block; width: 100%;">
      <a [href]="slide.href">
        <div class="carousel-caption-section d-none d-md-block">
          <h3>{{slide.title}}</h3>
          <p>{{slide.description}}</p>
        </div>
      </a>
    </slide>
  </ng-container>
</carousel> 