<div class="background-image">
    <app-nav 
        style="display: inline-block;"
        [isDarkBg]="false">
    </app-nav> 

    <div class="contact">
        <h3>
            {{'asumanaktuy@gmail.com'}}
        </h3>
        <h4>
            {{
                'instagram: @asumanaktuy'
            }}
        </h4>
    </div>
</div>