import { Component, OnInit } from '@angular/core';

interface slideInfo {
  src: string,
  title: string,
  description: string,
  href: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  public sliders: slideInfo[] = [
    {
      title: 'Blinded',
      description: 'SF MARCH (2018 - 2023)',
      src: 'blinded.png',
      href: '/works/blinded'
    },
    {
      title: 'Ayşe Bowls',
      description: 'Gallery Zmix/ SF MARCH (2016- 2023)',
      src: 'ayse.png',
      href: '/works/ayse'
    },
    {
      title: 'Hammam Bowls',
      description: 'SF MARCH (2019 - 2023)',
      src: 'hammam.png',
      href: '/works/hammam'
    },
    {
      title: 'Tohumlu Kabak',
      description: '2021',
      src: 'tohumlu2.png',
      href: '/works/kabak'
    },
    {
      title: 'Blinded',
      description: 'Yadigar 2023',
      src: 'blinded.png',
      href: '/works/blinded'
    },
    {
      title: 'Intentionality',
      description: 'SF MARCH 2019',
      src: 'intentionality2.png',
      href: '/works/intentionality'
    },
    {
      title: 'Hammam Bowls',
      description: 'Yadigar 2023',
      src: 'hammam.png',
      href: '/works/hammam'
    },
    {
      title: 'Hammam Bowls',
      description: 'Yadigar 2023',
      src: 'hammam1.png',
      href: '/works/hammam'
    },
    {
      title: 'Soap',
      description: 'Yadigar 2023',
      src: 'soap1.png',
      href: 'works/sabun'
    },
    {
      title: 'How Do We Lose Our Authenticity?',
      description: 'Gazeteciler Cemiyeti Muzesi 2022',
      src: 'onk2.png',
      href: '/works/onk'
    },
    {
      title: 'Intentionality',
      description: 'SF MARCH 2019',
      src: 'intentionality.png',
      href: '/works/intentionality'
    },
    {
      title: 'Soap',
      description: 'Yadigar 2023',
      src: 'soap2.png',
      href: 'works/sabun'
    },
    {
      title: 'How Do We Lose Our Authenticity?',
      description: 'Gazeteciler Cemiyeti Muzesi 2022',
      src: 'onk1.png',
      href: '/works/onk'
    },
    {
      title: 'Fatma',
      description: 'SF MARCH 2016',
      src: 'fatma.png',
      href: '/works/fatma'
    },
    {
      title: 'Tohumlu Kabak',
      description: '2021',
      src: 'tohumlu1.png',
      href: '/works/kabak'
    },
    {
      title: 'Nalın',
      description: 'Yadigar 2023',
      src: 'nalin.png',
      href: '/works/nalin'
    },
    // {
    //   title: 'Konuşanlar',
    //   description: 'Gazeteciler Cemiyeti Muzesi 2022',
    //   src: 'konusanlar.png',
    //   href: '/works/konusanlar'
    // },
    {
      title: 'Punctum',
      description: 'Yadigar 2023',
      src: 'punctum.png',
      href: '/works/punctum'
    },
    {
      title: 'Nalın',
      description: 'Yadigar 2023',
      src: 'nalin2.png',
      href: '/works/nalin'
    },
    {
      title: 'Hamam Taşı',
      description: 'Yadigar 2023',
      src: 'hammamtasi2.png',
      href: '/works/hamamtasi'
    },
  ]


  public mobileSliders: slideInfo[] = [
    {
      title: 'Blinded',
      description: 'SF MARCH (2018 - 2023)',
      src: 'assets/home/mobile/blinded.png',
      href: '/works/blinded'
    },
    {
      title: 'Ayşe Bowls',
      description: 'Gallery Zmix/ SF MARCH (2016- 2023)',
      src: 'assets/home/mobile/ayse.png',
      href: '/works/ayse'
    },
    {
      title: 'Hammam Bowls',
      description: 'SF MARCH (2019 - 2023)',
      src: 'assets/home/mobile/hammam.png',
      href: '/works/hammam'
    },
    {
      title: 'Tohumlu Kabak',
      description: '2021',
      src: 'assets/home/mobile/tohumlu2.png',
      href: '/works/kabak'
    },
    {
      title: 'Blinded',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/blinded.png',
      href: '/works/blinded'
    },
    {
      title: 'Intentionality',
      description: 'SF MARCH 2019',
      src: 'assets/home/mobile/intentionality2.png',
      href: '/works/intentionality'
    },
    {
      title: 'Hammam Bowls',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/hammam.png',
      href: '/works/hammam'
    },
    {
      title: 'Hammam Bowls',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/hammam1.png',
      href: '/works/hammam'
    },
    {
      title: 'Soap',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/soap1.png',
      href: 'works/sabun'
    },
    {
      title: 'How Do We Lose Our Authenticity?',
      description: 'Gazeteciler Cemiyeti Muzesi 2022',
      src: 'assets/home/mobile/onk2.png',
      href: '/works/onk'
    },
    {
      title: 'Intentionality',
      description: 'SF MARCH 2019',
      src: 'assets/home/mobile/intentionality.png',
      href: '/works/intentionality'
    },
    {
      title: 'Soap',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/soap2.png',
      href: 'works/sabun'
    },
    {
      title: 'How Do We Lose Our Authenticity?',
      description: 'Gazeteciler Cemiyeti Muzesi 2022',
      src: 'assets/home/mobile/onk1.png',
      href: '/works/onk'
    },
    {
      title: 'Fatma',
      description: 'SF MARCH 2016',
      src: 'assets/home/mobile/fatma.png',
      href: '/works/fatma'
    },
    {
      title: 'Tohumlu Kabak',
      description: '2021',
      src: 'assets/home/mobile/tohumlu1.png',
      href: '/works/kabak'
    },
    {
      title: 'Nalın',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/nalin.png',
      href: '/works/nalin'
    },
    {
      title: 'Konuşanlar',
      description: 'Gazeteciler Cemiyeti Muzesi 2022',
      src: 'assets/home/mobile/konusanlar.png',
      href: '/works/konusanlar'
    },
    {
      title: 'Punctum',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/punctum.png',
      href: '/works/punctum'
    },
    {
      title: 'Nalın',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/nalin2.png',
      href: '/works/nalin'
    },
    {
      title: 'Hamam Taşı',
      description: 'Yadigar 2023',
      src: 'assets/home/mobile/hammamtasi2.png',
      href: '/works/hamamtasi'
    },
  ]
  // fix the turkish characters in title and description
  ngOnInit(): void {
  }

}
