import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

interface PhotoCounts {
  [key: string]: { desktop: number, mobile: number};
}

interface ProjectDescription {
  english: string,
  turkish: string,
  title: string,
  teqnique: string
}
interface DescriptionConfig {
  [key: string]: ProjectDescription
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  private projectId: string = 'nalin'
  private imageCount = 8
  private descriptionConfig: DescriptionConfig = {
    blinded: {
      title: `KÖR/ BLINDED`,
      english: `The collection consists of artistic objects produced by utilizing the memory of the body, and it is a kind of exploration into the healing effects of art. The artist, seeking ways to develop new connections with her body, improve communication and listen to her body, and interpret images, designed a collaboration between her mind and body. By closing the artist's eyes and listening to her body, she created the artworks as a result of this collaboration. The Blinded collection, thus produced, redefines the notion of what is truly functional and blind, while also representing the individual's journey of self-discovery.`,
      turkish: `Bedenin hafızasını kullanarak, gözü kapalı biçimde üretilmiş sanatsal objelerden oluşan koleksiyon, sanatın iyileştirici etkisi üzerine bir tür araştırmadır.  Bedeniyle yeni bağlar geliştirme, iletişimini iyileştirme, bedenini dinleme ve  imgeleri okuma üzerine yollar arayan sanatçı, zihni ve bedeni arasında bir işbirliği tasarladı. Bu işbirliği için gözlerini kapatıp, bedenini dinleyerek yaratım yaptı. Bu şekilde üretilen Blinded koleksiyonu, gerçekte işlevsiz ve kör olanı yeniden tanımlarken, insanın kendine yolculuğunun temsili olarak karşımıza çıkar..`,
      teqnique: `Handmade, Porcelain`
    },
    nalin: {
      title: `NALIN/ PATTERN`,
      english: `Ceramic pattens created by putting the footprints of the mentors, friends, family members and students accompanying the artist's life journey. Pattens are placed in this exhibition as a representation of gratitude. It is not specified to whom the footprints on the ceramic pattens belong to. Apart from who they are, these people are represeted with the trace they have left, which are the most precious mementos of the artist's life experience.`,
      turkish: `Sanatçının yaşam yolculuğunda ona eşlik eden hoca, arkadaş, aile bireyleri ve öğrencilerinin ayak izleri alınarak oluşturulmuş. Nalınlar, sergide bir şükran temsili olarak yer alır. Seramik Nalınlar üzerinde yer alan ayak izlerinin kime ait olduğu belirtilmemiştir. Kim olduklarının ötesinde bıraktıkları izle temsil edilen bu insanlar, sanatçı için kendi hayat deneyimin en kıymetli yadigarlarıdır.`,
      teqnique: `Molded, Porcelain`
    },
    hammam: {
      title: `HAMAM TASI/ BATH BOWL`,
      turkish: `Bu heykelsi form, sanatçının hafıza çalışmaları ve araştırmalarının bir uzantısıdır. Sanatçı, bu form üzerinde klasik hamam tası desenini insan derisi ile ilişkilendirmiştir. En büyük organımız olan derimiz, aslında birçok bilgi ve anıyı biriktiren bir hafızadır. Hamam taslarının arınma ve temizlenme fonksiyonları ile deri hafızasındaki bilgilerin dönüşümü sembolize edilmiştir.`,
      english: `This sculptural form is an extension of the artist's memory studies and research. He associated the classical Turkish bath bowl pattern with human skin on this form. Our skin, which is our largest organ, is actually a memory that accumulates a lot of information and memories. The purification and cleaning functions of the bath bowls and the transformation of the information in the skin's memory are symbolized.`,
      teqnique: `Handmade, Porcelain`
    },
    sabun: {
      title: `SABUN/ SOAP`,
      turkish: `Seramik Sabun serisi, 1953'ten 1980'lere kadar Aydın'lı Aktüy ailesi tarafından tescilli olarak kendi özel reçetelerine uygun üretilmiş Cevdet Aktüy ve Muhsin Aktüy sabunlarının orijinal boyutlarında porselen yeniden üretimleridir. Sanatçı, ailesinden ve çocukluğundan aktardığı belleğin temsili olan sabunları kendi yaratım biçiminde bugüne taşımıştır. Yeni hafızalar üreterek, belleğin kimlik üzerindeki izlerini belirgin haline getiren iş, sanatçının hem yolculuğunun başlangıcını, hem de kılavuz izini oluşturmaktadır.`,
      english: `Porcelain reproductions of Cevdet Aktüy and Muhsin Aktüy soaps, produced by the Aktüy Family of Aydın from 1953 to the 1980s, in their original dimensions and with their own special recipe. The artist has brought soaps, which are the representations of the memory she conveyed from her family and childhood, to the present day in her method of creation. Reproducing new memories, this work marks the traces of the memory on the identity, realizing both the beginning of the artist's journey, and the guidelines of it.`,
      teqnique: `Molded, Porcelain`
    },
    konusanlar: {
      title: `KONUŞANLAR/ SPEAKERS`,
      turkish: `İleri dönüştürülmüş buluntu nesnelerin ve sanatçının ilkokul fotoğrafından stilize edilmiş bir desenin kullanıldığı yerleştirme eser, kırılgan ve eski buluntu parçalar ile bir kimliğin inşasındaki temel etkileri  temsil ediyor. Konuşmanın suç olarak tahtada teşhir edildiği bir sınıfta çocuk olmak, atanan kimlik, kendini bulma, bir toplumsal grup  içinde varlığını inşa etme gibi alt metinleri ile koleksiyon, sanatçının  zaman kavramı ile oynayan hikayeci yönünü ortaya koyuyor.`,
      english: `The installation artwork, composed of upcycled found objects and a stylized pattern derived from the artist's elementary school photograph, represents the fundamental influences in constructing an identity using fragile and aged artifacts. The collection, with its underlying themes of being a child in a classroom where speaking is deemed as a crime, assigned identities, self-discovery, and constructing one's presence within a social group, showcases the artist's narrative approach that plays with the concept of time.`,
      teqnique: `Handmade, Porcelain, Glass`
    },
    punctum: {
      title: `PUNCTUM/ PUNCTUM`,
      turkish: `Punctum, beklenmedik şekilde ortaya çıkan, açıklanmaya gerek olmayan, tamamen kişisel etki demektir. Sanatın iyileştirme gücünü ortaya çıkarma amacıyla sanatçı, kendisinin de bizzat deneyimlediği ve izleyici katılımına açık Punctum performansları tasarlamaktadır. Koleksiyonda, Punctum performansları sonucunda üretilen eserleri yer alan sanatçı, zihninde dönüştürmek istediği görüntüleri ve anları hamam taslarının üstüne çizer ve boyar. Boyadığı bu tasları suda yıkar. Yıkanmış ve  boyası aktığı için bozulmuş resimlerde yeni bir bakış ve başlangıç için Punctum etkisi arar. Sanatçının arınma ve yenilenme ritüeli olan çalışma,  ortaya birbirinden yaratıcı eserler ve hikayeler ortaya çıkarmıştır.`,
      english: `In order to reveal the healing power of art, the artist has designed Punctum performances that she experienced herself. The artist, whose works produced as a result of Punctum performances are included in the collection, draws and paints the images and moments he wants to transform in his mind on the bath stones. He washes these painted stones in water. In paintings that have been washed and deteriorated as the paint flows, he looks for a Punctum effect for a new look and beginning. The performance, which is the purification and renewal ritual of the artist, has created creative art pieces and stories under the collection.`,
      teqnique: `Molded, Porcelain`
    },
    ayse: {
      title: `AYŞE`,
      turkish: `‘Çiçekler Kırılgan Değildir’ koleksiyonu altında yer alan AYŞE serisi, beyaz seramik çiçek  formundaki eserlerden oluşur. Güçlü ve amorf jestlerin rahatlıkla fark edildiği seri ismini, sanatçının annesinden almıştır. Serinin fikirsel alt yapısında, Anne ile kadın kavramlarının iç içe geçer.  Her biri birbirinden farklı formda eserlerin estetik özelliklerinin ötesinde,  birbirleri arasında kurguladığı bütünlük hissi naif bir haz olarak paylaşılır.`,
      english: `The AYŞE series, which is part of the 'Flowers Are Not Fragile' collection, consists of artworks in the form of white ceramic flowers. The series, characterized by strong and amorphous gestures, takes its name from the artist's mother. Within the conceptual framework of the series, the concepts of motherhood and womanhood intertwine. Beyond the aesthetic qualities of each unique artwork, there is a shared sense of unity that is delicately appreciated.`,
      teqnique: `Handmade, Porcelain`
    },
    fatma: {
      title: `FATMA`,
      turkish: `Kırılgan, keskin ve gerilimi yüksek çiçek formunda seramik objelerden oluşan koleksiyon üç farklı seriyi içerir. Sanatçının büyük annesinin isminden ilham alan FATMA serisi, onun rengarenk çiçeklerle dolu bahçesinden referansla rengarenk seramik çalışmalardan oluşmaktadır. Tarih boyunca önemli ve güçlü kadınlara verilen Fatma ismi, saygı değerlik, güç ve güzellik gibi özellikleri temsil eder.  Teknik olarak merkezi denge noktasından çepere doğru açılan, incelen ve keskinleşen bu seramik çiçekler, sanatçının anneannesinin bahçesinde özenle koruma altına alınmış, çocukluktan sakınılan, otoriter bir güzelliğe dönüşümün hikayesini temsil eder. `,
      english: `The collection consists of fragile, sharp, and tension-filled ceramic objects in the form of flowers. It consists of three different series. The FATMA series, inspired by the artist's grandmother's name, is composed of colorful ceramic works referencing her garden full of vibrant flowers. Throughout history, the name Fatma has been given to important and powerful women, symbolizing qualities such as respectability, strength, and beauty.  Technically, these ceramic flowers, opening from the central balance point towards the periphery, becoming delicate and sharp, represent the story of transformation from childhood, where they were protected and preserved in the artist's grandmother's garden, to an authoritarian beauty.`,
      teqnique: `Handmade, Porcelain`
    },
    intentionality: {
      title: `KASITLI FİNCANLAR/INTENTIONAL GLASS`,
      turkish: `Keskin ve düzeltilmemiş kenarları ile kalıptan yeni çıkmış objeleri andıran koleksiyon, dikkat edilmeden kullanıldığında dudağı kesecek kadar tehlikeli bir tehdit yaratır.  Bir tarafı zımparalanmış, diğer tarafı ham bırakılmış mavi saplar,  mavinin tüm mirasını taşır. Mavi, eserlerde hem derinlik hem de coğrafi  göndermelerini barındırır. Yarısı ehlileştirilmiş diğer yanı tamamlanmamış iki yüzeyli ve çatışmalı bir his yaratır.  Odaklanma, tekinsizlik ve tehdit kavramları etrafında konumlanmış eserler,kullanıcısını zorla anda kalmaya ikna ederken, estetik bir oyuna da davet eder.`,
      english: `The collection, resembling objects freshly removed from a mold with sharp and unrefined edges, poses a potentially dangerous threat if used inattentively, capable of cutting one's lip. The blue handles, with one side sanded and the other left raw, carry the entire legacy of blue. Blue encompasses both depth and geographical references within the artworks. The half-tamed, half-uncompleted dual surfaces create a conflicted sensation. The artworks, centered around the concepts of focus, unease, and threat, persuade the viewer to forcibly stay in the present moment while also inviting them to engage in an aesthetic play. `,
      teqnique: `Handmade, Porcelain`
    },
    onk: {
      title: `OTANTİKLİĞİMİZİ NASIL KAYBEDERİZ?/ HOW WE LOSE OUR AUTHENTICITY?`,
      turkish: `Koleksiyon, zaman kavramını anlatıma doğrudan dahil eden performatik sanatsal objelerden oluşur. Sanatçı birbirinden farklı yüzeylere uyguladığı primitif desenleri ve anlatımları, içindeki çocuk ile sesli olarak konuşurak hikayeleştirir. Çizim ve boyamadan sonra, bu desenli yüzeyleri porselen kapama uygulaması ile tamamen kapatır. Bu katmanlı olarak  üretilmiş eserler, zamanla kabuklanması ve hikayelerin yeniden ortaya çıkması ile tamamlanır. Hikayelerin üretilme, örtünme ve ortaya çıkma biçimleri zamanın döngüsel niteliğinin altını çizer.`,
      english: `The collection consists of performative art objects that directly incorporate the concept of time into their expression. The artist narrates and contextualizes primitive patterns and expressions applied to various surfaces by speaking out loud with the child within, turning them into stories. After drawing and painting, the artist completely covers these patterned surfaces with porcelain glaze. These layered artworks are completed through the process of aging and the reemergence of stories. The ways in which stories are created, concealed, and revealed highlight the cyclical nature of time.`,
      teqnique: `Handmade, Porcelain`
    },
    kabak: {
      title: `TOHUMLU KABAK/ PUMPKIN WITH SEEDS `,
      turkish: `Çamurun içinde çimlenen tohumlarla üretilen kabak formundaki sanatsal nesnelerden oluşan koleksiyon, çamurun bir yaşam alanı olarak hayat vermesi üzerine bir araştırmanın çıktılarıdır. Toprak çamuru bir zihin yatağı ve fikirleri birer tohum olarak metoforlaştıran sanatçı, seramik malzemesini anlatımın doğrudan bir parçası olarak kullanır. Fikir, tohum, zaman, mukavemet, sabır, umut, çaba kavramları etrafında ortaya çıkmış performatif  sanatsal nesneler, pişme, çimlenme, çürüme ve yeniden çamura dönüşen bir döngünün de temsilidir.`,
      english: `The collection, composed of artistic objects in the form of pumpkins grown from seeds within the mud, is the outcome of research into how mud breathes life into a living space. The artist metaphorically conceptualizes the earthy mud as a mental bed and ideas as seeds, utilizing ceramic material as a direct part of the expression. The performative art objects that emerge around the concepts of idea, seed, time, resilience, patience, hope, and effort also represent a cycle of transformation involving baking, sprouting, decay, and returning to mud. They symbolize the continuous cycle of life  and rebirth.`,
      teqnique: `Handmade, Porcelain`
    },
    hamamtasi: {
      title: `HAMAM TASI/ BATH BOWL`,
      turkish: `Sanatçının kendi yaşam deneyimlerinden bir imge olarak seçtiği ve üçlemenin son bölümü olarak ürettiği 22 cm seramik hamam tasları Punctum performansları sırasında üzerine yapılması planlanan müdahaleler düşünülerek sade ve desensiz olarak tasarlandı. Orijinal formu ve boyutundan farklı olarak, sanatçı tarafından üretilmiş porselen taslar, performanslar sırasında ortaya çıkan tasarımlar ile yer almaktadır.`,
      english: `Bath bowl is designed to be plain and unpatterned, considering the assembly planned to be created throughout the Punctum performance, 22 cm ceramic bath bowls were chosen as an image from her own life experience and produced as the last part of the trilogy. Unlike it's original form and size, porcelain bath bowls are featured with the designs that emerge during the performances of the artist.`,
      teqnique: `Molded, Porcelain`
    },
  }
  private imageConfig: PhotoCounts = {
    "blinded": {desktop: 10, mobile: 25},
    "nalin": {desktop: 10, mobile: 14},
    "hammam": {desktop :6, mobile: 8},
    "sabun": { desktop: 5, mobile: 12},
    "konusanlar": {desktop: 2, mobile: 0},
    "punctum": { desktop: 6, mobile: 6},
    "ayse": {desktop: 5, mobile: 9},
    "fatma": {desktop: 11, mobile: 21},
    "intentionality": {desktop: 6, mobile: 12},
    "onk": {desktop: 10, mobile: 9 },
    "kabak": {desktop: 10, mobile: 18 },
    "hamamtasi": {desktop: 8, mobile: 8 }

  }
  public description: ProjectDescription = {
    title: '',
    turkish: '',
    english: '',
    teqnique: ''
  }
  
  public images: string[] = []
  public mobileImages: string[] = []
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('id') || 'nalin' ;
      let imageCount = this.imageConfig[this.projectId] || 2
      for (let index = 0; index < this.imageConfig[this.projectId].desktop; index++) {
        this.images[index] =  `assets/images/${this.projectId}/desktop/${index+1}.png`
      }
      for (let index = 0; index < this.imageConfig[this.projectId].mobile; index++) {
        this.mobileImages[index] =  `assets/images/${this.projectId}/mobile/${index+1}.png`
      }
      this.images = [...this.images]
      this.mobileImages = [...this.mobileImages]
      this.description = this.descriptionConfig[this.projectId]

    });
  }

}
