<nav 
    class="navbar navbar-expand-lg position-absolute w-100" 
    style="z-index: 9999;"
    [class.navbar-dark]="isDarkBg"
    [class.navbar-white-bg]="!isDarkBg"
    [class.navbar-dark-bg]="isDarkBg && !isCollapsed">
    <div class="container-fluid">
        <a 
        class="navbar-brand nav-link fs-1" 
        [class.text-light]="isDarkBg"
        [class.navbar-gray]="!isDarkBg"
        style="margin-left: 5%; margin-top: 2%;" 
        routerLink="/">
            Asuman Aktüy
        </a>
      <button 
        class="navbar-toggler" 
        style="margin-right: 5%; margin-top: 2%;"
        type="button" 
        (click)="isCollapsed = !isCollapsed" 
        aria-controls="navbarNav" 
        aria-expanded="false" 
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse fs-2 justify-content-end" 
        style="margin-right:12%; margin-top: 3%; margin-left: 6%;" 
        id="navbarNav" 
        [collapse]="isCollapsed">
        <ul class="navbar-nav">
          <li class="nav-item dropdown" style="margin-right: 10%;" bsDropdown>
            <a 
            class="nav-link dropdown-toggle" 
            [class.text-light]="isDarkBg"
            bsDropdownToggle 
            role="button" 
            aria-haspopup="true" 
            aria-expanded="false">
                Works
            </a>
            <ul 
              class="dropdown-menu bg-light" 
              [class.dropdown-menu-dark-bg]="isDarkBg"
              *dropdownMenu>
              <ng-container *ngFor="let link of links;">
                <li>
                  <a 
                  class="dropdown-item" 
                  [class.text-dark]="!isDarkBg"
                  [class.dropdown-menu-link-white]="isDarkBg"
                  [href]="link.href">
                  {{link.name}}
                </a>
              </li>
              </ng-container>
            </ul>
          </li>
          <li class="nav-item" style="margin-right: 10%;">
            <a 
            class="nav-link" 
            [class.text-light]="isDarkBg"
            routerLink="/about">
                About
            </a>
          </li>
          <li class="nav-item" style="margin-right: 10%;">
            <a 
            class="nav-link" 
            [class.text-light]="isDarkBg"
            routerLink="/contact">
                Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  