<div class="about">
    <app-nav 
        style="display: inline-block;"
        [isDarkBg]="false">
    </app-nav>

    <div class="content">
        <p>Biyografi</p>

        <p>
        1966 yılında Aydın’da doğan sanatçı 1989 Dokuz Eylül Üniversitesinden mezun olduktan sonra 1989-1991 yılları arasında İzmir Resim ve Heykel Müzesi Seramik Eğitim Atölyesi’nde seramik sanatı üzerine eğitim aldı. 2003-2005 yılları arasında Türk Silahlı Kuvvetleri Rehabilitasyon Merkezi’nde sanatla terapi araştırma ve uygulamalarına katıldığı Seramik ile Rehabilitasyon programında çalıştı.
        2015 yılında 400 lise öğrencisini seramikle tanıştıran ‘’Çamura Merhaba’’ adlı projeyi gerçekleştirdi.
        </p>
        2014 yılında İstanbul Üniversitesi Çapa Tıbbi Psikiyatri Fakültesi’nde ‘Ruhsa Bozukluklar için Sanat Terapisi’ eğitimine katıldı. Seramik alanındaki deneyimini sanat terapisinde kullanarak üretimler gerçekleştiren Aktüy,
        Sanat Psikoterapi Derneği (SPD) üyesidir.
        <br/>
        <br/>
        
        Sanatçı 2017 yılında Container adlı multidisipliner atölyeyi kurarak, sanatsal ve terapatik birikimlerini bireysel ve kolektif üretimlerle çoğalttı. Kendi adını taşıyan atölye/galerisinde Aktüy, kendinden başlayarak: arındırma, iyi olma hallerini artırma ve sanatın iyileştirme gücünü ortaya çıkarma üzerine kurguladığı multidisipliner çalışmalarına devam ediyor. Halen İstanbul Üniversitesi Tıp Fakültesi’nde eğitimini tamamladığı bölümde çamurun sanat terapisindeki yeri ile ilgili deneyimlerini paylaşmaktadır.
        <br/>
        <br/>
        
        Katıldığı Sergiler
        1990 İzmir Resim Heykel Müzesi Karma Seramik Sergisi
        2001 İncirlik Hava Üssü Karma Seramik Sergisi
        2016 3.İstanbul Seramik Günleri Karma Sergisi
        2022 İstanbul Kişisel Sergisi ‘’Yadigar’’
        2022 Türkiye Gazeteciler Cemiyeti Basın Müzesi ‘’EyÖzgürlük’’ Karma Sergisi
        2024 Bahçeşehir Üniversitesi Kişisel Sergi ‘’KILL YOUR MOTHER’’
        <br/>
        <br/>
        
        ENG
        <br/>
        <br/>

        Born in Aydin in 1966, following her graduation from Dokuz Eylul University in 1989, 
        between 1989-1991, the artist studied on ceramic art at the Ceramic Education Workshop of Izmir Painting and Sculpture Museum.
        Between 2003 and 2005, she worked in the Rehabilitation with Ceramics program, where she participated in art therapy research and practices at the Turkish Armed Forces Rehabilitation Center.In 2015, she realized the project "Hello to Mud", which introduced 400 high school students with ceramics.
        <br/>
        <br/>
        
        In 2014 , she attended the 'Art Therapy for Mental Disorders' training at Istanbul University Capa Faculty
        of Medical Psychiatry. Aktuy, who makes productions by using her experience in ceramics in art therapy, is a member of the Art Psychotherapy Association (SPD).
        <br/>
        <br/>
        
        By establishing the multidisciplinary workshop called Container in 2017, the artist multiplied her artistic
        and therapeutic knowledge with individual and collective productions.
        In the workshop/gallery that bears her name, Aktuy continues her multidisciplinary studies, which are based
        on purification, increasing well- being and revealing the healing
        <br/>
        <br/>

        EXHIBITIONS ATTENDED
        1990 Izmir Painting and Sculpture Museum Group Ceramics Exhibition
        2001 Incirlik Air Base Mixed Ceramics Exhibition
        2016 3rd Istanbul Ceramics Days Group Exhibition
        2022 Istanbul Solo Exhibition “Relic”
        2022 #EyFreedom Group Exhibition at the Turkish Journalists Association Press Museum
        2024 Bahçeşehir University Solo Exhibition KILL YOUR MOTHER
    </div>
</div>